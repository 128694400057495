<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
              <template v-slot:body>
                <b-overlay :show="loader">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                            <b-row>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Profession Type" vid="profession_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="profession_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_accommodation.profession_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="data.profession_type_id"
                                    :options="professionTypeList"
                                    id="profession_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="data.profession_type_id === 1">
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="data.profession_type_id === 2">
                                <ValidationProvider name="Officer Type" vid="officer_type_id" rules="required|min_value:1" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="4"
                                    label-for="officer_type_id"
                                >
                                    <template v-slot:label>
                                    {{ $t('portal.officer_type') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    id="officer_type_id"
                                    :options="officerTypeList"
                                    v-model="data.officer_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="data.profession_type_id === 1">
                                <ValidationProvider name="Grade From" vid="grade_from" rules="required|min_value:1" v-slot="{ errors }">
                                <b-form-group
                                    label-for="grade_from"
                                >
                                    <template v-slot:label>
                                    {{ $t('portal.grade_from') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    id="grade_from"
                                    :options="gradeList"
                                    v-model="data.grade_from"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="data.profession_type_id === 1">
                                <ValidationProvider name="Grade To" vid="grade_to" rules="required|min_value:1" v-slot="{ errors }">
                                <b-form-group
                                    label-for="fair_id"
                                >
                                    <template v-slot:label>
                                    {{ $t('portal.grade_to') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    id="grade_to"
                                    :options="gradeList"
                                    v-model="data.grade_to"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            </b-row>
                        </b-form>
                        </ValidationObserver>
                        <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                            <b-row class="p-4">
                            <!-- <b-col style="background-color: #B6D0E2;" lg="6" class="offset-lg-3 p-2">
                                <template>
                                <h6 style="" class="text-sm-center">{{ $t('tradeFairConfig.stall_info_details') }}</h6>
                                </template>
                            </b-col> -->
                            </b-row>
                            <b-row>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Room Type" vid="room_type" rules="required|min_value:1" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="room_type"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.room_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    id="room_type"
                                    :options="roomTypeList"
                                    v-model="detailsFormData.room_type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('elearning_config.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                            </b-row>
                        </b-form>
                        </ValidationObserver>
                        <br/>
                    <!-- List  -->
                    <b-table-simple bordered>
                        <thead>
                        <tr class="bg-primary">
                            <th style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="width:8%">{{ $t('elearning_config.room_type') }}</th>
                            <!-- <th style="width:10%">{{ $t('ditfConfig.security_perc') }}</th> -->
                            <th style="width:10%">{{ $t('globalTrans.action') }}</th>
                        </tr>
                        </thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in data.details" :key="index">
                                <b-td>
                                    {{ $n(index + 1) }}
                                </b-td>
                                <b-td>
                                {{ getRoomType(item.room_type) }}
                            </b-td>
                                <b-td>
                                <span class="d-flex text-center">
                                    <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                    <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                </span>
                                </b-td>
                            </b-tr>
                            <!-- <b-tr v-if="data.details.length <= 0">
                                <td colspan="3" class="text-center">
                                    <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                </td>
                            </b-tr> -->
                        </b-tbody>
                    </b-table-simple>
                    <b-row class="text-right">
                        <b-col>
                        <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                        <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                        <!-- <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button> -->
                        <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{data}}</pre> -->
  </b-container>
</template>
  <script>
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { gradeSetupStore, gradeSetupUpdate, gradeSetupShow } from '../../api/routes'
  export default {
    name: 'Form',
    props: ['id'],
    data () {
      return {
        valid: null,
        loader: false,
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        errors: [],
        editIndex: '',
        data: {
          profession_type_id: 0,
          officer_type_id: 0,
          grade_to: 0,
          grade_from: 0,
          details: []
        },
        detailsFormData: {
          room_type: 0
        },
        locationIndex: ''
      }
    },
    created () {
      if (this.id) {
        this.getGradeSetupInfo()
      }
    },
    watch: {
    },
    mounted () {
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      loading: function () {
        return this.$store.state.commonObj.loading
      },
      gradeList: function () {
      return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
      },
      roomTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.roomTypeSetupList.filter(item => item.status === 1)
      },
      orgTypeList () {
          const orgTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government Organization' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' }
      ]
          return orgTypeList
      },
      professionTypeList () {
          const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি', text_en: 'Government', text_bn: 'সরকারি' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government' : 'বেসরকারি', text_en: 'Private', text_bn: 'বেসরকারি' }
      ]
          return professionTypeList
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা' }
        ]
      }
    },
    methods: {
      getRoomType (id) {
        const dataRoomType = this.$store.state.TrainingElearning.commonObj.roomTypeSetupList.filter(item => item.status === 1).find(item => item.value === id)
        if (this.currentLocale === 'bn') {
          return dataRoomType !== undefined ? dataRoomType.text_bn : ''
        } else {
          return dataRoomType !== undefined ? dataRoomType.text_en : ''
        }
      },
      EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.toString().replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.toString().replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
      // percentage () {
      //   const securityFee = (parseFloat(this.detailsFormData.processing_fee) * parseFloat(this.detailsFormData.security_perc)) / 100
      //   this.detailsFormData.security_fee = isNaN(securityFee) ? '' : securityFee
      // },
      cancelItem () {
          this.detailsFormData = {}
          this.editIndex = ''
          this.$refs.form1.reset()
      },
      editItem (item, index) {
          this.detailsFormData = Object.assign({}, item)
          this.editIndex = index
      },
      deleteItem (index) {
        this.$swal({
          title: this.$t('globalTrans.deleteConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            // this.flag_status = 2
            // this.saveUpdate()
            this.data.details.splice(index, 1)
            this.editIndex = ''
          }
        })
          // this.data.details.splice(index, 1)
      },
      async addItem () {
        let result = []
        if (this.editIndex !== '') {
            const editList = [...this.data.details]
            editList.splice(parseInt(this.editIndex), 1)
            result = [...editList]
        } else {
            result = this.data.details
        }
        const roomType = this.detailsFormData.room_type
        const newData = result.find(item => item.room_type === roomType)
        if (typeof newData === 'undefined') {
            if (this.editIndex !== '') {
                // this.data.details[parseInt(this.editIndex)] = this.detailsFormData
                this.data.details[parseInt(this.editIndex)].room_type = this.detailsFormData.room_type
            } else {
               this.data.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
            }
            this.detailsFormData = {
              room_type: 0
            }
            this.editIndex = ''
        } else {
            this.$toast.error({
                title: '!',
                message: 'Item already added',
                color: '#ee5253'
            })
        }
        // reset form
        this.$nextTick(() => {
            this.$refs.form1.reset()
        })
      },
      // finalSave () {
      //   this.$swal({
      //     title: this.$t('globalTrans.final_save_msg'),
      //     showCancelButton: true,
      //     confirmButtonText: this.$t('globalTrans.yes'),
      //     cancelButtonText: this.$t('globalTrans.no'),
      //     focusConfirm: false
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.flag_status = 2
      //       this.saveUpdate()
      //     }
      //   })
      // },
      async saveUpdate () {
        var check = await this.$refs.form.validate()
        if (check) {
          if (!this.data.details.length) {
            return this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: 'Add at least one item!',
              color: 'red'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          this.loader = true
          const loadingState = { loading: false, listReload: false }
          if (this.id) {
            result = await RestApi.putData(trainingElearningServiceBaseUrl, `${gradeSetupUpdate}/${this.id}`, this.data)
          } else {
            result = await RestApi.postData(trainingElearningServiceBaseUrl, gradeSetupStore, this.data)
          }
          this.loader = false
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          if (result.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-4')
            } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
      },
      async getGradeSetupInfo () {
        this.loader = true
        const resultData = await RestApi.getData(trainingElearningServiceBaseUrl, gradeSetupShow + '/' + this.id)
        const tmpData = resultData.data
        const detailsData = tmpData.details.map(item => {
          return {
            room_type: item.room_type
          }
        })
        this.data.details = detailsData
        this.data = tmpData
        this.loader = false
      }
    }
  }
  </script>
